<template>
	<section class="task-list">
		<!-- LOADING SKELTON -->
		<div class="loading-skelton" v-if="loading">
			<el-skeleton :loading="loading" animated>
				<template #template>
					<div v-for="index in 3" :key="index" class="task-card" animated>
						<span class="status-skelton"></span>
						<div class="task-info">
							<el-skeleton-item class="key" variant="text" style="width: 60%" />
							<el-skeleton-item
								class="value"
								variant="text"
								style="width: 40%"
							/>
							<el-skeleton-item
								class="key"
								variant="text"
								style="margin-right: 16px"
							/>
							<el-skeleton-item
								class="value"
								variant="text"
								style="width: 30%"
							/>
						</div>
						<span class="task-hour">
							<div class="action-btn">
								<el-button type="text">
									<el-skeleton-item
										class="value"
										variant="button"
										style="width: 20px; height: 20px"
									/>
								</el-button>
								<el-button type="text">
									<el-skeleton-item
										class="value"
										variant="button"
										style="width: 20px; height: 20px"
									/>
								</el-button>
							</div>
						</span>
					</div>
				</template>
			</el-skeleton>
		</div>

		<div v-if="!loading">
			<div v-for="item in taskList" :key="item">
				<div v-if="item.tasks.length">
					<div v-for="task in item.tasks" :key="task">
						<div class="task-card">
							<span class="status"></span>
							<div class="task-info">
								<span class="key">Project: </span>
								<span class="value"> {{ item.project_name }}</span
								><br />
								<span class="key">Task Name:</span>
								<span class="value">{{ task.task_name }}</span
								><br />

								<span class="key">Task Description:</span>
								<span class="desc">{{ task.task_description }}</span
								><br />
								<span class="key">Effort:</span>
								<span class="value">{{ task.total_hours }} hrs</span><br />
							</div>
							<span
								class="task-hour"
								v-if="task.task_approval_status === 'Pending'"
							>
								<!-- <div>
                    <span class="key">Hours: </span>
                    <span class="value">04</span>
                    </div> -->
								<!-- <fa class="watch-icon" :icon="['fas', 'clock']" /> -->
								<div class="action-btn">
									<el-button type="text" @click="EditTaskModal(item, task)">
										<fa class="edit" :icon="['fas', 'edit']" />
									</el-button>
									<el-button type="text" @click="DeleteTaskModal(task)">
										<fa class="delete" :icon="['fas', 'trash']" />
									</el-button>
								</div>
							</span>
						</div>
						<!-- </el-col>
                  </el-row> -->
					</div>
				</div>
			</div>
		</div>
		<!-- Modal for edit task -->

		<el-drawer
			v-model="editTaskDilog"
			direction="rtl"
			custom-class="demo-drawer"
			size="40%"
			ref="drawer"
		>
			<div class="new-task-cover">
				<el-form
					class="task-form"
					:model="taskObj"
					:rules="rules"
					ref="taskObj"
					@submit="$event.preventDefault()"
					@keypress.enter="UpdateTask"
				>
					<!-- project Name  -->
					<div class="input-group">
						<label>Select Project</label>
						<el-form-item prop="projectId">
							<el-select
								style="display: block"
								v-model="taskObj.projectId"
								placeholder="Select Project"
								disabled
							>
								<el-option
									v-for="item in projects"
									:key="item"
									:label="item.name"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<!-- <select class="select-css">
            <option v-for="item in projects" :key="item" value="volvo">
              {{ item }}
            </option>
          </select> -->
					</div>

					<!-- Task Name  -->
					<div class="input-group">
						<label>Task Name</label>
						<!-- <input type="text" class="email" /> -->
						<el-form-item prop="taskName">
							<el-input
								type="text"
								placeholder="Please Enter Task Name"
								v-model="taskObj.taskName"
								@keypress.enter.prevent
								maxlength="50"
								show-word-limit
							>
							</el-input>
						</el-form-item>
					</div>

					<!-- Description -->
					<div class="input-group">
						<label>Task Description</label>
						<el-form-item prop="desc">
							<el-input
								type="textarea"
								maxlength="256"
								show-word-limit
								:autosize="{ minRows: 3, maxRows: 6 }"
								placeholder="Enter Description"
								v-model="taskObj.desc"
							>
							</el-input>
						</el-form-item>
					</div>

					<!-- Hours -->
					<div class="input-group">
						<label>Effort (in hours)</label>
						<el-form-item prop="hours">
							<el-input
								type="number"
								@keypress.enter.prevent
								placeholder="Please Enter Effort in Hours"
								v-model="taskObj.hours"
								min="0"
								max="24"
								step="0.01"
								maxlength="4"
								show-word-limit
							>
							</el-input>
						</el-form-item>
						<!-- <input type="number" class="email form-control" /> -->
					</div>

					<!-- Action btn -->
					<div class="action-btn">
						<button
							class="btn close-btn color-black"
							@click="editTaskDilog = false"
						>
							Close
						</button>

						<button
							class="btn submit-btn primary-bg color-white"
							@click="UpdateTask"
						>
							Update Task
						</button>
					</div>
				</el-form>
			</div>
		</el-drawer>

		<!-- Modal for delete task -->
		<el-dialog center title="Delete Task" width="30%" v-model="deleteTaskDilog">
			<p class="delete-note">Are you sure you want to delete the task ?</p>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="deleteTaskDilog = false">Cancel</el-button>
					<el-button
						type="primary"
						:loading="deleteTaskLoader"
						@click="DeleteTask"
						>Confirm</el-button
					>
				</span>
			</template>
		</el-dialog>
		<el-empty
			v-if="totalTask === 0 && loading === false"
			description="No task found"
		></el-empty>
	</section>
</template>

<script>
	import Task from "../../services/task.js";
	export default {
		name: "TaskList",
		data() {
			var checkHours = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("Please input the age"));
				}
				setTimeout(() => {
					if (value > 24) {
						callback(new Error("Hours should be betweeen 0 - 24"));
					} else {
						callback();
					}
				}, 1000);
			};
			return {
				deleteTaskLoader: false,
				loading: true,
				totalTask: "",
				taskList: [],
				deleteTaskDilog: false,
				editTaskDilog: false,
				taskObj: {
					projectId: "",
					taskName: "",
					hours: "",
					desc: "",
					task_id: "",
				},
				task_date: "",
				task_id: "",
				projects: [],
				rules: {
					projectId: [
						{
							required: true,
							message: "Project name is required.",
							trigger: "blur",
						},
					],
					taskName: [
						{
							required: true,
							message: "Task name is required.",
							trigger: "blur",
						},
					],
					hours: [{ validator: checkHours, trigger: "blur" }],
					desc: [
						{
							required: true,
							message: "Description is required.",
							trigger: "blur",
						},
					],
				},
			};
		},
		mounted() {
			this.getAllTask();
			//this.getProjectList();
		},
		computed: {
			getNumbers() {
				//  this.clicked_date = this.$store.getters.getNumbers;
				return this.$store.getters.getNumbers;
			},
			getCounter() {
				return this.$store.getters.getCounter;
			},
		},
		watch: {
			getNumbers() {
				this.loading = true;
				this.getAllTask();
			},
			getCounter() {
				this.getAllTask();
			},
		},
		methods: {
			getProjectList() {
				//this.userID
				Task.ProjectList(303)
					.then((res) => {
						this.projects = res.data.project_list;
						//call getalltask api again
					})
					.catch((err) => {
						//display error resposne here
						console.log(err);
					});
			},
			getAllTask() {
				let current_date = this.getNumbers;
				//  this.loading = true;
				let data = {
					date: current_date,
				};
				Task.getAllTask(data)
					.then((res) => {
						this.taskList = [];
						this.taskList = res.data.task_summary;
						this.totalTask = 0;
						for (let i = 0; i < this.taskList.length; i++) {
							if (this.taskList[i].tasks.length > 0 && this.taskList.length > 0) {
								this.totalTask = 1;
								break;
							}
						}
						this.loading = false;
					})
					.catch((err) => {
						//display error resposne here
						console.log(err);
					});
			},
			//when you click on delete icon
			DeleteTaskModal(task) {
				if (this.projects.length === 0) {
					this.getProjectList();
				}
				this.deleteTaskDilog = true;
				this.task_id = task.task_id;
			},
			//triggers when you delete
			DeleteTask() {
				this.deleteTaskLoader = true;
				Task.deleteTask(this.task_id)
					.then((res) => {
						this.deleteTaskLoader = false;
						console.log(res);
						this.deleteTaskDilog = false;
						this.$store.dispatch("addHours", 1);
						//call getalltask api again
						this.getAllTask();
					})
					.catch((err) => {
						//display error resposne here
						console.log(err);
					});
			},
			//triggered when you click on edit icon
			EditTaskModal(item, task) {
				if (this.projects.length === 0) {
					this.getProjectList();
				}
				//Bind values
				// console.log(task);
				this.editTaskDilog = true;
				this.taskObj.projectId = item.project_id;
				this.taskObj.taskName = task.task_name;
				this.taskObj.hours = task.total_hours;
				this.taskObj.desc = task.task_description;
				this.task_date = task.task_date;
				this.task_id = task.task_id;
			},
			//trigger when clicked update task in modal
			UpdateTask() {
				console.log("update is being called");
				this.$refs["taskObj"].validate((valid) => {
					if (valid) {
						let data = {
							task_name: this.taskObj.taskName,
							task_description: this.taskObj.desc,
							task_hours: parseFloat(this.taskObj.hours),
							project_id: this.taskObj.projectId,
							task_date: this.task_date,
						};
						Task.updateTask(data, this.task_id)
							.then((res) => {
								console.log(res);
								this.editTaskDilog = false;
								this.$store.dispatch("addHours", 1);
								//call getalltask api again
								this.getAllTask();
							})
							.catch((err) => {
								//display error resposne here
								console.log(err);
							});
					} else {
						return;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.new-task-cover {
		width: 500px;
		margin: auto;

		@media (max-width: 960px) {
			width: 340px;
			display: grid;
			place-content: center;
			margin: auto;
		}

		.action-btn {
			display: flex;
			padding: 20px;
			justify-content: space-around;

			.submit-btn,
			.close-btn {
				width: 45%;
				margin-right: 10px;
			}
		}
	}
	.task-list {
		overflow: hidden;
		.task-card {
			// height: 75px;
			margin: 1.5rem 0px;
			border: 1px solid #eee;
			display: flex;
			background-color: white;

			// background: chartreuse;
			box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);

			.status {
				display: inline-block;
				width: 10px;
				// height: 75px;
				background: $primary;
			}

			.status-skelton {
				display: inline-block;
				width: 10px;
				background: #eee;
			}

			.task-info {
				width: 90%;
				// background: #eee;
				// padding: 0px 10px;
				padding: 0.4rem 10px;

				.key {
					display: inline-block;
					margin: 0.4rem;
				}

				.desc {
					font-size: 14px;
					display: inline-block;
					margin: 0.4rem;
					color: #123;
				}

				.value {
					@extend .key;
					font-size: 18px;
					font-weight: bold;
				}
			}

			.task-hour {
				width: 10%;
				// padding: 0px 10px;
				display: flex;
				justify-content: space-evenly;
				flex-direction: column;

				.key {
					display: inline-block;
					margin: 0.4rem;
				}

				.value {
					font-size: 2em;
					font-weight: bold;
					margin-left: 6px;
				}

				.watch-icon {
					font-size: 16px;
					color: #eee;
				}
			}

			.action-btn {
				display: flex;
				justify-content: space-around;
				font-size: 16px;
				align-items: baseline;

				@media (max-width: 960px) {
					height: 100%;
					flex-direction: column;
				}

				.edit {
					color: $primary;
					cursor: pointer;
				}
				.delete {
					color: #ff00009c;
					cursor: pointer;
				}
			}
		}
		.delete-note {
			text-align: center;
		}
	}
</style>
