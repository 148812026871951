<template>
  <section class="create-task">
    <el-row>
      <el-col  :xs="24" :md="14" :sm="12" :lg="18" :span="18">
        <h3  class="date-heading">{{ moment(getNumbers).format("Do MMMM YYYY") }}</h3>
      </el-col>
      <el-col :xs="12"  :md="7" :sm="9" :lg="5" :span="5">
        <div @click="updateTaskFlag" :class="{ future_date: getNumbers > moment().format('YYYY-MM-DD') }"  class="new-task-btn place-center">
			Add Task
        </div>
      </el-col>
      <!-- info icon  -->
       <el-col :xs="12" :md="3" :sm="3" :span="1" :lg="1" class="info-button">
         <div> 
			<el-tooltip content="Info" placement="bottom" effect="light">
               <el-button @click="centerDialogVisible = true" icon="el-icon-info" circle ></el-button>
			</el-tooltip>
         </div>
      </el-col>
    </el-row>
    <!-- create task form -->
	<el-drawer
		v-model="createTaskFlag"
		direction="rtl"
		custom-class="demo-drawer"
		size="40%"
		ref="drawer">
    <div class="new-task-cover" >
      <el-form
        class="task-form"
        :model="taskObj1"
        :rules="rules"
        ref="taskObj1"
        @submit="$event.preventDefault()"
		@keypress.enter = "__createTask($event)"
      >
        <div class="place-between pace-icon">
          <div>
            <p>Please fill the following task details.</p>
          </div>
          <!-- <img src="@/assets/images/icon.png" alt="pacewisdom" width="70" /> -->
        </div>

        <!-- Task Name  -->
        <div class="input-group">
          <label>Select Project</label>
          <el-form-item prop="projectId">
            <el-select
              style="display: block" 
              v-model="taskObj1.projectId"
              placeholder="Select Project"
            >
              <el-option
                v-for="item in projects"
                :key="item"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <!-- Task Name  -->
        <div class="input-group">
          <label>Task Name</label>
          <el-form-item prop="taskName">
            <el-input
              type="text" @keypress.enter.prevent
              placeholder="Please Enter Task Name"
              v-model="taskObj1.taskName"
              maxlength="50"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </div>

        <!-- Description -->
        <div class="input-group">
          <label>Task Description</label>
          <el-form-item prop="desc">
			<el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6}"
              placeholder="Enter Description"
              v-model="taskObj1.desc"
              maxlength="256"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </div>

        <!-- Hours -->
        <div class="input-group">
          <label>Effort (in hours)</label>
          <el-form-item prop="hours">
            <el-input
              type="number" @keypress.enter.prevent
              placeholder="Please Enter Effort in Hours"
              v-model="taskObj1.hours"
              step="0.01"
              min="0"
              max="24"
               maxlength="4"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </div>
		<p>
			Note: Please follow below format if you are giving efforts in minutes
			<ul>
				<li>15 Minutes: 0.25 (Ex: 7 hours 15 minutes is - 7.25)</li>
				<li>30 Minutes: 0.50 (Ex: 7 hours 30 minutes is - 7.50)</li>
				<li>45 Minutes: 0.75 (Ex: 7 hours 45 minutes is - 7.75)</li>
			</ul>
		</p>
        <!-- Action btn -->
        <div class="action-btn">
          <button class="btn close-btn color-black" @click="updateTaskFlag">
            Close
          </button>
          <el-button
            class="btn submit-btn primary-bg color-white" native-type="submit"
            @click="__createTask($event)"
			:loading="createTaskLoading"
          >
            Create Task
          </el-button>
        </div>
      </el-form>
	</div>
</el-drawer>
    <!-- information modal popup -->
    <el-dialog  v-model="centerDialogVisible" width="35%" center>
        <h1 style="text-align:center;">NOTE</h1>
        <hr/>
     <div class="task-intro">
         
      <p class="note">
        <!-- Note:  -->
        
       <!-- <span> <center>Please follow the instructions</center></span> -->
        <ul>
          <li>Please uncheck the checkbox, if you are absent on that day.</li>
          <li>Make sure you have the checkbox checked on the left side<br/> of the calender list before entering task details.</li>
        </ul>
      </p>
    </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">Cancel</el-button>
          <!-- <el-button type="primary" @click="centerDialogVisible = false"
            >Confirm</el-button
          > -->
        </span>
      </template>
    </el-dialog>
  </section>
</template>

<script>
	import moment from "moment";

	import Task from "../../services/task.js";
	export default {
		name: "TaskForm",

		data() {
			var checkHours = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("Please input hours"));
				}
				setTimeout(() => {
					if (value > 24) {
						callback(new Error("Hours should be betweeen 0 - 24"));
					} else {
						callback();
					}
				}, 1000);
			};
			return {
				createTaskLoading: false,
				centerDialogVisible: false,
				taskObj1: {
					projectId: "",
					taskName: "",
					hours: 0.0,
					desc: "",
				},
				rules: {
					projectId: [
						{
							required: true,
							message: "Project name is required.",
							trigger: "blur",
						},
					],
					taskName: [
						{
							required: true,
							message: "Task name is required.",
							trigger: "blur",
						},
					],
					hours: [{ validator: checkHours, trigger: "blur" }],
					desc: [
						{
							required: true,
							message: "Description is required.",
							trigger: "blur",
						},
					],
				},
				createTaskFlag: false,
				projects: [],
				count: null,
			};
		},
		created: function () {
			this.moment = moment;
		},
		computed: {
			getNumbers() {
				return this.$store.getters.getNumbers;
			},
		},
		//mounted() {
		// this.P3 = sessionStorage.getItem("P3");
		// if (!this.P3) {
		// 	setTimeout(() => {
		// 		//this.centerDialogVisible = true;
		// 		this.P3 = sessionStorage.setItem("P3", 1234);
		// 	}, 2000);
		// 	// this.getProjectList();
		// }
		//	},
		methods: {
			/**
			 * @description create task form validation -  submit
			 */
			__createTask(ev) {
				console.log(ev);
				this.$refs["taskObj1"].validate((valid) => {
					if (valid && !this.createTaskLoading) {
						this.createTaskLoading = true;
						let data = {
							task_name: this.taskObj1.taskName,
							task_description: this.taskObj1.desc,
							task_hours: parseFloat(this.taskObj1.hours),
							project_id: this.taskObj1.projectId,
							task_date: this.getNumbers,
						};
						Task.createTask(data)
							.then(() => {
								console.log("crete task is called");
								this.createTaskLoading = false;
								//console.log(res);
								this.createTaskFlag = false;
								//call getalltask api gain
								this.$store.dispatch("addCounter", 1);
								this.$store.dispatch("addHours", 1);
								this.resetmodal();
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						return;
					}
				});
			},
			resetmodal() {
				this.$refs["taskObj1"].resetFields();
			},

			getProjectList() {
				Task.ProjectList()
					.then((res) => {
						//console.log("getall task resposne", res);
						this.projects = res.data.project_list;
						//call getalltask api again
					})
					.catch((err) => {
						//display error resposne here
						console.log(err);
					});
			},
			updateTaskFlag() {
				if (this.getNumbers > moment().format("YYYY-MM-DD")) {
					return;
				}
				if (this.projects.length === 0) {
					this.getProjectList();
				}
				this.createTaskFlag = !this.createTaskFlag;
				this.resetmodal();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.create-task {
		.future_date {
			opacity: 0.6;
			cursor: not-allowed !important;
			display: none;
		}
		.date-heading {
			@media (max-width: 960px) {
				text-align: center;
			}
		}
		.info-button {
			margin: auto;
			text-align: right;
		}
		.el-button.is-circle {
			border: 1px solid $primary;
			&:hover {
				background: $primary;
				color: #fff;
			}
		}
		.new-task-btn {
			width: 150px;
			height: 35px;
			border: 1px solid $primary;
			cursor: pointer;
			margin: 18px;
			font-weight: 500;

			&:hover {
				background: $primary;
				color: #fff;
				margin: 18px;
			}
		}
		.new-task-cover {
			width: 500px;
			margin: auto;

			@media (max-width: 960px) {
				width: 340px;
				display: grid;
				place-content: center;
				margin: auto;
			}

			.action-btn {
				display: flex;
				padding: 20px;
				justify-content: space-around;

				.submit-btn,
				.close-btn {
					width: 45%;
					margin-right: 10px;
				}
			}
		}
	}
</style>
